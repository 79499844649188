import React, {Component} from 'react';
import Songs from "../api/Songs";

class Home extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      song: ""
    }
    this.handleClick = this.handleClick.bind(this);
  }


  handleClick() {
    this.setState((state, props) => {
      return {song: state.song}
    }) 
  }

    render(){
        return <>
        <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container-fluid">
      <div class="header-container d-flex align-items-center">
        {/* <div class="logo mr-auto">
          <h1 class="text-light"><a href="index.html"><span>Music by Mike Tragas</span></a></h1>
          <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid" /></a>
        </div> */}

        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li class="active"><a href="#header">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#music">Music</a></li>
            {/* <li class="drop-down"><a href="">Drop Down</a>
              <ul>
                <li><a href="#">Drop Down 1</a></li>
                <li class="drop-down"><a href="#">Drop Down 2</a>
                  <ul>
                    <li><a href="#">Deep Drop Down 1</a></li>
                    <li><a href="#">Deep Drop Down 2</a></li>
                  </ul>
                </li>
                <li><a href="#">Drop Down 3</a></li>
              </ul>
            </li> */}
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>

  
  <section id="hero" class="d-flex align-items-center">
    <div class="container text-center position-relative" data-aos="fade-in" data-aos-delay="200">
      <h1 class="#">Music makes the world a better place.</h1>
      <a href="#music" class="btn-get-started scrollto">Check my music</a>
    </div>
  </section>

  <main id="main">
    
    <section id="about" class="about">
      <div class="container">
        <div class="row content">
          <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
            <h2>My journey into music</h2>
            {/* <h3>It all began when i picked a classic guitar and started scratching the chords...</h3> */}
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="200">
            <h3>
              My journey into music began when I picked up a guitar and started playing it. I practiced hours and hours until I could start writing my own songs - and here I am today. Each song tells a story or passes a message that is based on reality.  <br /><q className="small bold">Music can take us anywhere - if we only listen.</q>
            </h3>
          </div>
        </div>
      </div>
    </section>
    {/* <section id="cta" class="cta">
      <div class="container">

        <div class="text-center" data-aos="zoom-in">
          <h3>Call To Action</h3>
          <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laforum.</p>
          <a class="cta-btn" href="#">Call To Action</a>
        </div>

      </div>
    </section> */}

    <section id="music" class="music section-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-right">
              <h2>My Songs</h2>
              <p>The songs below are written and composed by Mike Tragas &copy;</p>
            </div>
          </div>
          <Songs />
        </div>
      </div>
    </section>

    <section id="contact" class="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-4" data-aos="fade-right">
            <div class="section-title">
              <h2>Contact</h2>
            </div>
          </div>

          <div class="col-lg-8" data-aos="fade-up" data-aos-delay="100">
            {/* <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe> */}
            <div class="info mt-4">
              <i class="icofont-google-map"></i>
              <h4>Location:</h4>
              <p>Stockholm, Sweden</p>
            </div>
            <div class="row">
              <div class="col-lg-6 mt-4">
                <div class="info">
                  <i class="icofont-envelope"></i>
                  <h4>Email:</h4>
                  <p>contact@miketragas.se</p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="info w-100 mt-4">
                  <i class="icofont-phone"></i>
                  <h4>Call:</h4>
                  <p>+46(0)70-455-11-64</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <footer id="footer">
    <div className="text-center">Mike Tragas &copy;</div>    
  </footer>
    </>
    }
}

export default Home;