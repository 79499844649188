import React, {useState, useEffect} from "react";

export default function Songs() {

  const [song, setSong] = useState("");

  const songs = [
    {
      id: 1,
      name: "Tell Me God",
      lyrics:
        "We live in a dark scary world where we have to survive this - why bother at all ? We have to live in a society, where everyone is ruined by anxiety. Now wake up, dress up, brush your teeth and don't forget to smile, to breathe. But who am i to judge, who am i to tell? I am just a human who wants to rebel. Why do some steal and why do some kill? Why do you give them the right to live? Tell me god, what's the plan? Are you up there having fun? Hello human, from the sky! Don't you worry don't you cry. You should be living, you should have fun. You should love your self and be a man..and don't forget that you are immortal and death is only just a portal. You humans! You need validation, you do everything to gain my attention. I told you everything from the start, to live to laugh and to love. Now listen to me and thank your mother who gave you life and you blossomed like a flower. Goodbye human i have to go, swallow your pride and make earth your home.",
        release: 1
    },
    {
      id: 2,
      name: "The ADHD song",
      lyrics:
        "No one told me life was hard. No one told me i should grow up, but i am a kid with ADHD and i have a lot of, fantasy. Raising thoughts are aching his mind, another human is left behind. Thank you so much ADHD - go fuck yourself and your fantasy. The school was a boring place, it turned out to be waste because i am a kid with ADHD and school is not for me. A pen, a guitar, a paper, a car, a cat, a rat, cheese, bees. What the hell is going on? My mind is under your control. The devil has control on me, you are the devil you ADHD. Ooh tell me please, are you a gift? are you a disease? Let go off me ADHD - get out of me, set mee free.",
        release: 1
    },
    {
      id: 3,
      name: "Loneliness",
      lyrics:
        "Loneliness you are so dark. Loneliness you tear me apart. Tonight it's me and you again. Loneliness you hate me don't you? Loneliness, what have i done to you? Another sleepless night, a night without a light. You followed me everywere, from my childhood to my adulthood and you were always there, turned my life to hell. But there is one thing i am thankful for. You taught me, self control and whenever i feel alone, i invite you to my home. Loneliness you are gone now, solitude welcome home - the lesson i learned, to accept myself i earned.",
        release: 1
    },
    {
      id: 4,
      name: "Boy, Man, King",
      lyrics:
        "I am one of these guys who gets emotional sometimes - and i feel that that the world is a threat and god did just left. He moved to a new location, created a new civilization, he summoned new creatures - these aren't human, these got virtues. I used to trust my emotions, I used to listen to my heart, but they got me screwed over and i shot them down, i had enough. I feel like a soldier in the battle, i feel like a boxer in a ring, it's time to wear the crown now, it's time to be a king. I ruled the earths kingdoms, i conquered the whole world, with blood, sweat, with tears and my fears were all gone.They told me to go soft in life, they told me to be a good boy but then i grew up weak and i struggled to find joy.A man has to go hard in life, a man has to become a king until he relizes that life's just a short dream.",
        release: 1
    },
    {
      id: 4,
      name: "Let Us Unite",
      lyrics:
        "It's Friday, it's dark outside, it is so cold, i am feeling old. So what do i do, to have some fun? I pick the guitar and I start strumming, ooo i am drumming. I am so excited to feel my brain releasing dopamine in every vein, my whole body is vibrating, my heart is energy creating. Forget the problems, there are no orders, there's only freedom, there's only love. Open up and love each other, love everyone, like you love your mother. Raise your hands, tonight! Let there be some human light. Because we are humans and we are together, let us unite let's shine forever.",
        release: 1
    },
    {
      id: 5,
      name: "Dont You Fear, Dear.",
      lyrics:
        "We are gonna die, someday. We are going to burn astray. We are going to fly so high. To universe and stars - perhaps we'll die on Mars. Am i a starman lost in space, am I a madman with an angry face? am i an allien lost in the human race? My spaceship is one of a kind, it doesn't belong to mankind. It's made of alien brain and when i launch it, there is no flame. The earth looks beautiful from here and i am about to shed a tear, but i am floating so high and in space it's hard to cry. Life is great here in the void - until i crash with an asteroid, until my space breaks into pieces i say farewell to you, human species.",
        release: 1
    },
    {
      id: 6,
      name: "Diana",
      lyrics: "Coming soon...",
      release: 0
    },
  ];

 function chooseSong(event, index) {
  event.preventDefault();
   setSong(songs[index]);
 }

 function addNewLine(song) {
  //  for (let i = 0; i < song; i++){
  //    while (song.lyrics.length < 50 ) {
  //      console.log(i);
  //    }
  //  }
 }
  return (
    <div className="row">
      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
            <h4 class="modal-title text-center">{song.name}</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <article className="lyric-article">{(song.release !== 0 ? song.lyrics : "Coming Soon..")}</article>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {songs.map((song, index) => (
        <div class="col-md-4 lyric-wrapper" id={song.id} key={index} >
          <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
            {addNewLine(song)}
            <button
              onClick={(event) => chooseSong(event, index)}
              data-toggle="modal"
              class="btn"
              data-target="#myModal"
            >
              <div class="icon">
              <a href="">{song.name}</a>
              </div>
            </button>

          </div>
        </div>
      ))}
    </div>
  );
}
